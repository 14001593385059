import * as React from "react";
import { graphql } from "gatsby";
import BlocTitle from "../components/elements/bloc-title";
import TextBackground from "../components/elements/text-background";
import BlocContent from "../components/elements/bloc-content";
import Layout from "../components/layout";
import Helmet from "react-helmet";
import MobileBackground from "../components/elements/mobile-background";

const AgencyPage = ({ data }) => {
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{data.strapiAgency.SEO.metaTitle}</title>
        <meta
          name="description"
          content={data.strapiAgency.SEO.metaDescription}
        />
        {/* <link rel="canonical" href="http://mysite.com/example" /> */}
      </Helmet>
      <div className="font-gotham text-white">
        <div className="-space-y-2 bg-yellow pt-2 pb-1 text-center">
          <BlocTitle
            styleName="text-xl lg:text-3xl font-gotham-ultra "
            content={data.strapiAgency.title}
          />
        </div>
        <TextBackground imgUrl={data.strapiAgency.imgBackground.url}>
          <MobileBackground
            imgMobUrl={data.strapiAgency.mobileBackground.url}
            bgVisible={data.strapiAgency.imgBackgroundVisible}
          >
            <BlocContent
              className="relative z-30 max-w-screen-md p-10"
              content={data.strapiAgency.content.data.content}
            />
          </MobileBackground>
        </TextBackground>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query strapiAgency {
    strapiAgency {
      title
      content {
        data {
          content
        }
      }
      imgBackground {
        url
      }
      imgBackgroundVisible
      SEO {
        metaTitle
        metaDescription
      }
      mobileBackground {
        url
      }
    }
  }
`;
export default AgencyPage;
